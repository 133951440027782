import { Store } from "@retailtune/types/lib/store";

export const storeType_INTISANOREICA = "INTISANOREICA";
export const storeType_CENTRI_ESTETICI = "CENTRI ESTETICI";
export const storeType_ERBORISTERIE = "ERBORISTERIE";
export const storeType_FARMACIE = "FARMACIE";
export const storeType_ALTRO = "ALTRO";

export const selectStoreMarker = (...storeTypes: string[]) => {
  for (let i = 0; i < storeTypes.length; ++i) {
    switch (storeTypes[i]) {
      case storeType_INTISANOREICA:
        return "/img/icons/pin/pin-intisanoreica.svg";
      case storeType_CENTRI_ESTETICI:
        return "/img/icons/pin/pin-centri-estetici.svg";
      case storeType_ERBORISTERIE:
        return "/img/icons/pin/pin-erboristerie.svg";
      case storeType_FARMACIE:
        return "/img/icons/pin/pin-farmacie.svg";
      case storeType_ALTRO:
        return "/img/icons/pin/pin-altro.svg";
      default:
        return "/img/icons/pin/pin-altro.svg";
    }
  }
  return "/img/icons/pin/pin-altro.svg";
};
