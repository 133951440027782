import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  CustomRenderer,
  getGoogleMapsLink,
  googleAutocompleteHandler,
  selectMapZoomFromPredictionLayer,
} from "@retailtune/google-maps-utils";
import { Store } from "@retailtune/types/lib/store";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Position } from "@retailtune/types/lib/geolocation";
import { AnalyticsAction, AnalyticsCategory, CTAOrigin } from "@retailtune/types/lib/analytics";
import {
  createAutocompleteHandler,
  createDebounceFn,
  createExpirationValue,
  getExpirable,
  retailTuneAutocompleteHandler,
  RT_API_STORES_GET,
  setExpirable,
  createPosition,
  createStoreOpeningTimeText,
  fetchUserDefaultPosition,
  fetchUserPosition,
  getDevice,
  getRadialDistanceFn,
  positionToLatLngLiteral,
  sortStoresByDistance,
  USER_POSITION_CONSENT,
  USER_STORAGE_CONSENT,
  validateHostname,
} from "@retailtune/utils";
import {
  createAutocomplete,
  createScrollButton,
  createSelect,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";
import { mapStyles } from "@/common/map";
import { HomepageTranslations } from "@/types/translations";

import "@retailtune/vanilla-ui-core/styles/autocomplete/Autocomplete.css";
import "@retailtune/vanilla-ui-core/styles/toast/Toast.css";
import "@retailtune/vanilla-ui-core/styles/select/Select.css";
import "@retailtune/vanilla-ui-core/styles/back-to-top/Back-to-top.css";
import { selectStoreMarker, storeType_INTISANOREICA } from "@/common/store-types";
import { showStorageBar } from "@/common/utils";

// --- Type definitions

// global variables defined in index.php
declare function sendGa4DL(category: AnalyticsCategory, action: AnalyticsAction, label: string, storeId: number): void;
declare const language: string;
// server time in seconds
declare const serverTime: number;
declare const translations: HomepageTranslations;

interface PositionState {
  defaultPosition: Position;
  userPosition: Position;
  currentPosition: Position;
}

interface StoreFilter {
  filterStoreType: string;
}

// user device type
let currentDevice = getDevice();

// holds the name of the current day (used in store-card creation)
let dayname: string;

// toast trigger function
let toast: (message: string) => void;
// scrolling HTMLElement update function
let updateScrollingElement: (newElementId: string) => void;

// position related
let position: PositionState;

// stores related
let allStores: Store[];
let filteredStores: Store[];
const storeMarkersMap = new Map<string, google.maps.Marker>();
const storeCardsMap = new Map<string, HTMLLIElement>();

// unique countries tag
let uniqueStoreTypes: string[];
let storeTypeNamesMap = new Map<string, string>();
let uniqueCountriyTags: string[];

const storeFilter: StoreFilter = {
  filterStoreType: "",
};

let googleMap: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let lastClickedMarker: { store: Store; origin: CTAOrigin } | null;
let markerCluster: MarkerClusterer;
// driving directions related
let directionsDestinationStoreCode: string | null;
let directionsOriginPosition: Position;
let directionsDestinationPosition: Position;
let destinationStoreMarker: google.maps.Marker;
let travelMode: google.maps.TravelMode;
let directionsInstructions: google.maps.DirectionsStep[];
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;

// consent related
let userPositionConsent = !!getExpirable<boolean>(USER_POSITION_CONSENT);
let userStorageConsent = !!getExpirable<boolean>(USER_STORAGE_CONSENT);

// tells whether the user has already been geolocated or not
let positionHasBeenFetched = false;

// # Main function
async function main() {
  dayname = new Date(serverTime * 1000).toLocaleDateString(language, { weekday: "short" }).toLocaleUpperCase();

  // fetching data
  const [hostname, defaultPosition] = await Promise.all([
    validateHostname(process.env.RETAILTUNE_KEY!),
    fetchUserDefaultPosition(process.env.RETAILTUNE_KEY!),
    fetchStores(language),
  ]);

  // # State initialization

  position = {
    defaultPosition,
    userPosition: defaultPosition,
    currentPosition: defaultPosition,
  };

  directionsOriginPosition = defaultPosition;

  // # Map initialization
  const mapEl = document.getElementById("rt_map") as HTMLDivElement;
  const mapCenter = positionToLatLngLiteral(position.currentPosition);

  googleMap = new google.maps.Map(mapEl, {
    zoom: 7,
    center: mapCenter,
    mapTypeControl: false,
    streetViewControl: false,
    styles: mapStyles,
    restriction: {
      latLngBounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(35.85960538675639, -0.34034211616514654),
        new google.maps.LatLng(48.02441243127172, 21.983876633834853)
      ),
    },
  });

  userMarker = new google.maps.Marker({
    map: googleMap,
    position: mapCenter,
    icon: "/img/icons/pin/pin-origin.svg",
  });

  infoWindow = new google.maps.InfoWindow();

  markerCluster = new MarkerClusterer({
    map: googleMap,
    markers: [],
    // algorithm: new CustomGridAlgorithm(googleMap, 20),
    renderer: new CustomRenderer(`/img/icons/cluster/cluster.svg`, "#000", infoWindow),
  });

  // # Driving Directions initialization
  destinationStoreMarker = new google.maps.Marker({
    map: googleMap,
    visible: false,
  });

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map: googleMap,
    polylineOptions: {
      strokeColor: "#14303a",
      strokeWeight: 3,
    },
  });

  const travelModesEl = document.getElementById("rt_travel_modes")!;

  const createTravelMode = (mode: google.maps.TravelMode, iconUrl: string) => {
    const travelModeEl = document.createElement("button");
    travelModeEl.id = mode;
    travelModeEl.classList.add("rt-travel-mode");
    if (mode === google.maps.TravelMode.DRIVING) {
      travelModeEl.classList.add("rt-travel-mode--selected");
    }
    const imgEl = document.createElement("img");
    imgEl.src = iconUrl;
    imgEl.alt = mode;

    travelModeEl.addEventListener("click", () => {
      document.querySelectorAll(".rt-travel-mode").forEach(el => el.classList.remove("rt-travel-mode--selected"));
      travelModeEl.classList.add("rt-travel-mode--selected");
      travelMode = mode;
      updateMapWithDirections();
    });
    travelModeEl.appendChild(imgEl);
    travelModesEl.appendChild(travelModeEl);
  };

  createTravelMode(google.maps.TravelMode.DRIVING, "/img/icons/travel/car.svg");
  createTravelMode(google.maps.TravelMode.WALKING, "/img/icons/travel/walk.svg");
  createTravelMode(google.maps.TravelMode.TRANSIT, "/img/icons/travel/train.svg");

  travelMode = google.maps.TravelMode.DRIVING;

  // shared Autocomplete search handler
  const searchHandler = createAutocompleteHandler(
    retailTuneAutocompleteHandler(process.env.RETAILTUNE_KEY!, {
      language,
      countries: uniqueCountriyTags,
    }),
    googleAutocompleteHandler()
  );

  // main autocomplete prediction click handler
  const mainPredictionClickHandler = (prediction: PredictionData) => {
    position.currentPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    const latLng = positionToLatLngLiteral(position.currentPosition);
    userMarker.setPosition(latLng);
    googleMap.setCenter(latLng);

    if (directionsDestinationStoreCode) {
      clearDrivingDirections();
      filterStores();
    }
    updateStores();

    googleMap.setZoom(selectMapZoomFromPredictionLayer(prediction));

    sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
  };

  createAutocomplete({
    anchor: "rt_main_autocomplete",
    searchHandler,
    predictionClickHandler: mainPredictionClickHandler,
    zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    placeholder: translations.k_autocomplete_placeholder,
    clearIcon: {
      path: "/img/icons/close.svg",
      position: "left",
    },
    searchIcon: {
      path: "/img/icons/search.svg",
      position: "right",
    },
  });

  // directions autocomplete prediction click handler
  const directionsPredictionClickHandler = (prediction: PredictionData) => {
    directionsOriginPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    updateMapWithDirections();
    sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
  };

  createAutocomplete({
    anchor: "rt_directions_autocomplete",
    searchHandler,
    predictionClickHandler: directionsPredictionClickHandler,
    zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    placeholder: translations.k_autocomplete_placeholder,
    searchIcon: { path: "/img/icons/search.svg", position: "right" },
  });

  // creating store type select
  const storeTypePlaceholder = translations.k_select_all_types;
  const [updateStoreTypeOptions] = createSelect({
    anchor: "rt_store_types_select",
    placeholder: storeTypePlaceholder,
    handleOptionClick: option => {
      if (option.value === storeTypePlaceholder) {
        storeFilter.filterStoreType = "";
      } else {
        for (const [key, value] of storeTypeNamesMap.entries()) {
          if (value === option.value) {
            storeFilter.filterStoreType = key;
          }
        }
      }
      if (directionsDestinationStoreCode) {
        clearDrivingDirections();
      }
      infoWindow.close();
      filterStores();
      updateStores();
    },
  });
  updateStoreTypeOptions(
    uniqueStoreTypes.map(type => ({
      value: storeTypeNamesMap.get(type)!,
      html: `<img src="${selectStoreMarker(type)}" /><span id="${type}">${storeTypeNamesMap.get(type)}</span>`,
    }))
  );

  [toast] = createToastMessagesContainer({
    anchor: "rt_storelocator",
    autoClose: 3000,
    position: "top-right",
  });

  const scrollingElID = currentDevice === "desktop" ? "rt_stores_area" : "rt_storelocator";
  const scrollingEl = document.getElementById(scrollingElID)!;

  [updateScrollingElement] = createScrollButton({
    anchorEl: "rt_storelocator",
    scrollingEl,
  });

  if (userStorageConsent) {
    showStorageBar(false);
  } else {
    const storageBarButtonEl = document.getElementById("rt_storage_bar_btn")!;
    storageBarButtonEl.addEventListener("click", () => {
      setExpirable(USER_STORAGE_CONSENT, {
        value: JSON.stringify(true),
        expiration: createExpirationValue(1, "years"),
      });
      showStorageBar(false);
    });
    showStorageBar(true);
  }

  if (currentDevice === "desktop") {
    const params = new URLSearchParams(window.location.search);
    const storeCode = params.get("code");
    directionsDestinationStoreCode = storeCode;
  }

  filterStores();
  showStoresOrDirections();

  showConsentModal(!userPositionConsent);
  if (userPositionConsent) {
    await getUserPosition();
    showStoresOrDirections();
  }

  // --- binding handlers to the document & window

  const resizeDebounce = createDebounceFn()(100);
  window.addEventListener("resize", () =>
    resizeDebounce(() => {
      const newDevice = getDevice();
      if (newDevice !== "desktop" && directionsDestinationStoreCode !== null) {
        // clear directions if mobile
        clearDrivingDirections();
        filterStores();
        updateStores();
      }
      if (currentDevice !== "desktop" && newDevice === "desktop") {
        // breakpoint from tablet to desktop
        updateScrollingElement("rt_stores_area");
      } else if (currentDevice === "desktop" && newDevice !== "desktop") {
        // breakpoint from desktop to tablet
        updateScrollingElement("rt_storelocator");
      }
      currentDevice = newDevice;
    })
  );

  // --- binding handlers to DOM elements

  const handleBoundsChange = () => {
    // do nothing if map is showing driving direcitons
    if (directionsDestinationStoreCode) {
      return;
    }
    updateStores();
    if (lastClickedMarker) {
      // if a marker click has been registered, it calls its handler
      handleElementClick(lastClickedMarker.store, lastClickedMarker.origin);
      lastClickedMarker = null;
    }
  };

  const boundsChangedDebounce = createDebounceFn()(300);
  googleMap.addListener("bounds_changed", () => boundsChangedDebounce(handleBoundsChange));

  const btnNearestStoreEl = document.getElementById("rt_nearest_stores_btn") as HTMLButtonElement;
  btnNearestStoreEl.addEventListener("click", async () => {
    showConsentModal(!userPositionConsent);
    if (userPositionConsent) {
      infoWindow.close();
      clearDrivingDirections();
      filterStores();
      await getUserPosition();
      showStoresOrDirections();
    }
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  });

  const directionsPaneCloseIcon = document.getElementById("rt_directions_pane_close_btn")!;
  directionsPaneCloseIcon.addEventListener("click", () => {
    clearDrivingDirections();
    filterStores();
    updateStores();
  });

  const btnConsentModalYes = document.getElementById("rt_btn_consent_modal_yes") as HTMLButtonElement;
  btnConsentModalYes.addEventListener("click", async () => {
    userPositionConsent = true;
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });

    await getUserPosition();
    showStoresOrDirections();
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  });

  const btnConsentModalNo = document.getElementById("rt_btn_consent_modal_no") as HTMLButtonElement;
  btnConsentModalNo.addEventListener("click", () => {
    userPositionConsent = false;
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  });
}

// ! this loads the application
(function () {
  let interval = setInterval(() => {
    try {
      // let any global variable initialization happen before loading the main function
      if (!(language && serverTime && translations)) throw new Error();
      clearInterval(interval);
      main();
    } catch (e) {}
  }, 50);
})();

// * --- Function definitions

async function getUserPosition() {
  const newPosition = await fetchUserPosition(position.defaultPosition);
  // updating position info with the new user position
  position.userPosition = newPosition;
  position.currentPosition = newPosition;
  directionsOriginPosition = newPosition;

  const latLng = positionToLatLngLiteral(position.currentPosition);
  userMarker.setPosition(latLng);
  googleMap.setCenter(latLng);

  if (!positionHasBeenFetched) {
    switch (newPosition.type) {
      case "html5":
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(translations.k_warning_position_generic_error);
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(translations.k_warning_position_generic_error);
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    positionHasBeenFetched = true;
  }
}

async function fetchStores(language: string) {
  const response = await fetch(RT_API_STORES_GET, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.RETAILTUNE_KEY}`,
    },
    body: JSON.stringify({ language }),
  });
  if (response.status !== 200) throw new Error(`[ERROR] cannot fetch stores (status ${response.status})`);

  allStores = await response.json();

  const storeTypesSet = new Set<string>();
  const countryTagsSet = new Set<string>();

  for (let i = 0; i < allStores.length; ++i) {
    // save store country tag
    countryTagsSet.add(allStores[i].country.tagISO31661Alpha2);

    // save store types
    allStores[i].storeTypeLabels.forEach((type, j) => {
      storeTypesSet.add(type);
      storeTypeNamesMap.set(type, allStores[i].storeTypes[j]);
    });

    // creating and caching the store card for this store
    const storeCard = createStoreCard(allStores[i]);
    storeCardsMap.set(allStores[i].storeCode, storeCard);

    // creating and caching the marker for this store
    const marker = new google.maps.Marker({
      position: { lat: allStores[i].latitude, lng: allStores[i].longitude },
      icon: selectStoreMarker(...allStores[i].storeTypeLabels),
    });
    marker.addListener("click", () => {
      infoWindow.setContent(createInfoWindowContent(allStores[i]));
      infoWindow.open({ anchor: marker });

      lastClickedMarker = { store: allStores[i], origin: "map" };
    });
    storeMarkersMap.set(allStores[i].storeCode, marker);
  }

  uniqueStoreTypes = Array.from(storeTypesSet);
  uniqueCountriyTags = Array.from(countryTagsSet);

  console.log(uniqueStoreTypes);
  console.log(storeTypeNamesMap);
  console.log(allStores);
}

// * filter all the available stores based on the store type (if any filter is set)
function filterStores() {
  // update filteredStores array
  if (storeFilter.filterStoreType.length === 0) {
    filteredStores = new Array<Store>(allStores.length);
    for (let i = 0; i < allStores.length; ++i) {
      filteredStores[i] = allStores[i];
    }
  } else {
    filteredStores = [];
    for (let i = 0; i < allStores.length; ++i) {
      // should skip store ?
      if (allStores[i].storeTypeLabels.some(type => type === storeFilter.filterStoreType)) {
        filteredStores.push(allStores[i]);
      }
    }
  }
  filteredStores.sort(sortStoresByDistance);

  // update markerCluster markers
  const markers = new Array<google.maps.Marker>(filteredStores.length);
  for (let i = 0; i < filteredStores.length; ++i) {
    markers[i] = storeMarkersMap.get(filteredStores[i].storeCode)!;
  }
  markerCluster.clearMarkers();
  markerCluster.addMarkers(markers);
}

function updateStores() {
  // mae sure map bounds are defined before continuing execution
  const mapBounds = googleMap.getBounds();
  const mapCenter = googleMap.getCenter();
  if (!(mapBounds && mapCenter)) {
    setTimeout(updateStores, 100);
    return;
  }

  const visibleStores: Store[] = [];
  const getDistance = getRadialDistanceFn(mapCenter.lat(), mapCenter.lng());

  const latLng = { lat: 0, lng: 0 };
  for (let i = 0; i < filteredStores.length; ++i) {
    // populating store cards
    latLng.lat = filteredStores[i].latitude;
    latLng.lng = filteredStores[i].longitude;
    if (mapBounds.contains(latLng)) {
      filteredStores[i].distance = getDistance(latLng.lat, latLng.lng);
      visibleStores.push(filteredStores[i]);
    }
  }
  visibleStores.sort(sortStoresByDistance);

  const storesEl = document.getElementById("rt_stores_list")!;
  storesEl.innerHTML = "";
  for (let i = 0; i < visibleStores.length; ++i) {
    storesEl.appendChild(storeCardsMap.get(visibleStores[i].storeCode)!);
  }

  // Update text showing filtered stores number
  const labelStoresCountEl = document.getElementById("rt_stores_count") as HTMLSpanElement;
  labelStoresCountEl.innerHTML =
    visibleStores.length === 1
      ? `<strong>1</strong> ${translations.k_store_found}`
      : `<strong>${visibleStores.length}</strong> ${translations.k_stores_found}`;
}

function createStoreCard(store: Store) {
  // * store card element
  const storeCardEl = document.createElement("li");
  storeCardEl.id = `rt_store_card_${store.storeCode}`;
  storeCardEl.classList.add("rt-store");
  storeCardEl.onclick = () => handleElementClick(store, "list");

  // * store card header section
  const headerEl = document.createElement("header");
  headerEl.classList.add("rt-store__heading");

  const storeNameEl = document.createElement("h2");
  storeNameEl.classList.add("rt-store-name");

  if (store.storeTypeLabels.includes(storeType_INTISANOREICA)) {
    storeNameEl.textContent = `${translations.k_tisanoreica_center} ${store.city}`;
  } else {
    storeNameEl.textContent = store.name;
  }

  headerEl.appendChild(storeNameEl);

  // * store card info section
  const storeInfoEl = document.createElement("div");
  storeInfoEl.classList.add("rt-store__info");

  const storeStreetEl = document.createElement("span");
  storeStreetEl.classList.add("rt-store-street");
  storeStreetEl.textContent = store.address1;
  storeInfoEl.appendChild(storeStreetEl);

  const storeCityEl = document.createElement("span");
  storeCityEl.classList.add("rt-store-city");
  storeCityEl.textContent = `${store.postalCode} ${store.city}`;
  storeInfoEl.appendChild(storeCityEl);

  const storePhoneEl = document.createElement("a");
  storePhoneEl.classList.add("rt-store-phone");
  storePhoneEl.href = `tel:${store.phone}`;
  storePhoneEl.onclick = () => handlePhoneClick(store, "list");
  storePhoneEl.textContent = store.phone;
  storeInfoEl.appendChild(storePhoneEl);

  if (store.whatsapp) {
    const storeWhatsappEl = document.createElement("a");
    storeWhatsappEl.classList.add("rt-store-whatsapp");
    storeWhatsappEl.href = `https://wa.me/${store.whatsapp}`;
    storeWhatsappEl.onclick = () => handleWhatsappClick(store, "list");
    storeWhatsappEl.textContent = translations.k_store_chat_now;
    storeInfoEl.appendChild(storeWhatsappEl);
  }

  const timeEl = document.createElement("time");
  timeEl.classList.add("rt-store-openings");
  timeEl.textContent = createStoreOpeningTimeText(
    serverTime * 1000,
    store,
    dayname,
    translations.k_opening_time_closed
  );
  storeInfoEl.appendChild(timeEl);

  // * store card CTA section

  const storeCTAEl = document.createElement("div");
  storeCTAEl.classList.add("rt-store__cta");

  if (store.storeLink) {
    const storeDetailsBtn = document.createElement("button");
    storeDetailsBtn.classList.add("rt-btn-primary", "rt-btn");
    storeDetailsBtn.onclick = () => handleStoreInfoClick(store, "list");
    storeDetailsBtn.textContent = translations.k_store_details;
    storeCTAEl.appendChild(storeDetailsBtn);
  }

  const drivingDirectionsBtn = document.createElement("button");
  drivingDirectionsBtn.classList.add("rt-btn-secondary", "rt-btn");
  drivingDirectionsBtn.onclick = () => handleDrivingDirectionsClick(store, "list");
  drivingDirectionsBtn.textContent = translations.k_store_driving_directions;
  storeCTAEl.appendChild(drivingDirectionsBtn);

  storeCardEl.appendChild(headerEl);
  storeCardEl.appendChild(storeInfoEl);
  storeCardEl.appendChild(storeCTAEl);

  return storeCardEl;
}

function createInfoWindowContent(store: Store) {
  // * info window element
  const infoWindowEl = document.createElement("div");
  infoWindowEl.classList.add("rt-iw-content");

  // * info window header section
  const headerEl = document.createElement("header");
  headerEl.classList.add("rt-iw__heading");

  const storeNameEl = document.createElement("h2");
  storeNameEl.classList.add("rt-iw-name");

  if (store.storeTypeLabels.includes(storeType_INTISANOREICA)) {
    storeNameEl.textContent = `${translations.k_tisanoreica_center} ${store.city}`;
  } else {
    storeNameEl.textContent = store.name;
  }

  headerEl.appendChild(storeNameEl);

  // * info window info section
  const storeInfoEl = document.createElement("div");
  storeInfoEl.classList.add("rt-iw__info");

  const storeStreetEl = document.createElement("span");
  storeStreetEl.classList.add("rt-iw__info-street");
  storeStreetEl.textContent = store.address1;
  storeInfoEl.appendChild(storeStreetEl);

  const storeCityEl = document.createElement("span");
  storeCityEl.classList.add("rt-iw__info-city");
  storeCityEl.textContent = `${store.postalCode} ${store.city}`;
  storeInfoEl.appendChild(storeCityEl);

  const storePhoneEl = document.createElement("a");
  storePhoneEl.classList.add("rt-iw__info-phone");
  storePhoneEl.href = `tel:${store.phone}`;
  storePhoneEl.onclick = () => handlePhoneClick(store, "map");
  storePhoneEl.textContent = store.phone;
  storeInfoEl.appendChild(storePhoneEl);

  if (store.whatsapp) {
    const storeWhatsappEl = document.createElement("a");
    storeWhatsappEl.classList.add("rt-iw__info-whatsapp");
    storeWhatsappEl.href = `https://wa.me/${store.whatsapp}`;
    storeWhatsappEl.onclick = () => handleWhatsappClick(store, "map");
    storeWhatsappEl.textContent = translations.k_store_chat_now;
    storeInfoEl.appendChild(storeWhatsappEl);
  }

  // * info window CTA section
  const storeCTAEl = document.createElement("div");
  storeCTAEl.classList.add("rt-iw__cta");

  if (store.storeLink) {
    const storeDetailsBtn = document.createElement("button");
    storeDetailsBtn.classList.add("rt-btn-primary", "rt-btn");
    storeDetailsBtn.onclick = () => handleStoreInfoClick(store, "map");
    storeDetailsBtn.textContent = translations.k_store_details;
    storeCTAEl.appendChild(storeDetailsBtn);
  }

  const drivingDirectionsBtn = document.createElement("button");
  drivingDirectionsBtn.classList.add("rt-btn-secondary", "rt-btn");
  drivingDirectionsBtn.onclick = () => handleDrivingDirectionsClick(store, "map");
  drivingDirectionsBtn.textContent = translations.k_store_driving_directions;
  storeCTAEl.appendChild(drivingDirectionsBtn);

  infoWindowEl.appendChild(headerEl);
  infoWindowEl.appendChild(storeInfoEl);
  infoWindowEl.appendChild(storeCTAEl);

  return infoWindowEl;
}

function updateMapWithDirections() {
  const destinationStore = allStores.find(s => s.storeCode === directionsDestinationStoreCode);
  if (!destinationStore) return;

  directionsDestinationPosition = createPosition({
    latitude: destinationStore.latitude,
    longitude: destinationStore.longitude,
  });

  const origin = positionToLatLngLiteral(directionsOriginPosition);
  const destination = positionToLatLngLiteral(directionsDestinationPosition);
  const request: google.maps.DirectionsRequest = {
    origin,
    destination,
    travelMode,
  };

  directionsService.route(request, (result, status) => {
    if (
      !result ||
      status === google.maps.DirectionsStatus.INVALID_REQUEST ||
      status === google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED ||
      status === google.maps.DirectionsStatus.ZERO_RESULTS
    ) {
      return toast(translations.k_warning_directions_no_results);
    }
    if (status === google.maps.DirectionsStatus.OK) {
      clearDrivingDirectionsInstructions();
      markerCluster.clearMarkers();
      infoWindow.close();
      showDirectionsPane(true);

      const route = result.routes[0].legs[0];

      directionsInstructions = route.steps;
      directionsRenderer.setOptions({
        directions: result,
        suppressMarkers: true,
      });
      userMarker.setPosition(origin);
      destinationStoreMarker.setPosition(destination);
      destinationStoreMarker.setVisible(true);
      const destinationIconUrl = selectStoreMarker(...destinationStore.storeTypeLabels);
      destinationStoreMarker.setIcon(destinationIconUrl);

      // update directions pane destination icons
      const directionsPaneEl = document.getElementById("rt_directions_pane")!;
      directionsPaneEl.style.setProperty("--directions-pane-icon", `url(${destinationIconUrl})`);

      // updating the directions pane UI
      const destinationInputEl = document.getElementById("rt_destination_input_text")!;
      destinationInputEl.textContent = `${destinationStore.name} - ${destinationStore.city}`;

      const originLabelTextEl = document.getElementById("rt_origin_label_text")!;
      originLabelTextEl.textContent = translations.k_your_position;

      const destinationLabelTextEl = document.getElementById("rt_destination_label_text")!;
      destinationLabelTextEl.textContent = `${destinationStore.address1}, ${destinationStore.city}`;

      const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
      for (let i = 0; i < directionsInstructions.length; ++i) {
        const step = document.createElement("li");
        step.classList.add("rt-instruction");
        step.innerHTML = `<strong>${i + 1}.</strong> <div>${directionsInstructions[i].instructions}</div>`;
        directionsInstructionsEl.appendChild(step);
      }
    }
  });
}

function showStoresOrDirections() {
  if (directionsDestinationStoreCode && currentDevice === "desktop") updateMapWithDirections();
  else updateStores();
}

function clearDrivingDirectionsInstructions() {
  directionsInstructions = [];
  const directionsInstructionsEl = document.getElementById("rt_directions_instructions")!;
  directionsInstructionsEl.innerHTML = "";
}

function clearDrivingDirections() {
  showDirectionsPane(false);
  directionsDestinationStoreCode = null;
  directionsRenderer.setDirections({ routes: [] });
  clearDrivingDirectionsInstructions();
  // reset travel mode value and appearance
  travelMode = google.maps.TravelMode.DRIVING;
  document.querySelectorAll(".rt-travel-mode").forEach(el => el.classList.remove("rt-travel-mode--selected"));
  document.getElementById(google.maps.TravelMode.DRIVING)!.classList.add("rt-travel-mode--selected");
  destinationStoreMarker.setVisible(false);
  userMarker.setIcon("/img/icons/pin/pin-origin.svg");
  userMarker.setPosition(positionToLatLngLiteral(position.currentPosition));
}

function showDirectionsPane(shouldShow: boolean) {
  const directionsPaneEl = document.getElementById("rt_directions_pane")!;
  if (shouldShow) {
    directionsPaneEl.classList.add("rt-directions-pane--visible");
    updateScrollingElement("rt_directions_pane");
  } else {
    directionsPaneEl.classList.remove("rt-directions-pane--visible");
    if (currentDevice === "desktop") {
      updateScrollingElement("rt_stores_area");
    } else {
      updateScrollingElement("rt_storelocator");
    }
  }
}

function showConsentModal(shouldShow: boolean) {
  const consentModalEl = document.getElementById("rt_position_consent_modal")!;
  if (shouldShow) {
    consentModalEl.classList.add("rt-modal--visible");
  } else {
    consentModalEl.classList.remove("rt-modal--visible");
  }
}

function handleElementClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "StoreClickListing" : "StoreClickMap";
  sendGa4DL("Store", "Click", `${eventName}-${store.city} ${store.address1}-${currentDevice}-${language}`, store.id);

  // the scroll behavior of the list on a marker click is disabled on mobile
  if (currentDevice !== "desktop") return;

  switch (origin) {
    case "list":
      const marker = storeMarkersMap.get(store.storeCode);
      if (!marker) break;
      infoWindow.setContent(createInfoWindowContent(store));
      infoWindow.open({
        map: googleMap,
        anchor: marker,
      });
      // zoom in on the newly opened info window
      googleMap.setZoom(12);
      break;
    case "map":
      const storesCard = document.getElementById(`rt_store_card_${store.storeCode}`);
      storesCard?.scrollIntoView({ behavior: "auto" });
      break;
  }
}

function handlePhoneClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "PhoneClickListing" : "PhoneClickMap";
  sendGa4DL("Store", "Click", `${eventName}-${store.city} ${store.address1}-${currentDevice}-${language}`, store.id);
}

function handleWhatsappClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "WhatsappClickListing" : "WhatsappClickMap";
  sendGa4DL("Store", "Click", `${eventName}-${store.city} ${store.address1}-${currentDevice}-${language}`, store.id);
}

function handleDrivingDirectionsClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "DirectionsClickListing" : "DirectionsClickMap";
  sendGa4DL("Store", "Click", `${eventName}-${store.city} ${store.address1}-${currentDevice}-${language}`, store.id);

  if (currentDevice === "desktop") {
    directionsDestinationStoreCode = store.storeCode;
    updateMapWithDirections();
  } else {
    window.open(getGoogleMapsLink(store), "_blank");
  }
}

function handleStoreInfoClick(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "DetailsClickListing" : "DetailsClickMap";
  sendGa4DL("Store", "Click", `${eventName}-${store.city} ${store.address1}-${currentDevice}-${language}`, store.id);
  window.open(store.storeLink);
}
