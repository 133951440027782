import { WidgetLandingTranslations } from "@/types/translations";
import { ProductIdentifier } from "@retailtune/types/lib/product-widget";
import { Store } from "@retailtune/types/lib/store";

export const fetchLandingSearchParams = (translations: WidgetLandingTranslations) => {
  let productType: ProductIdentifier | null;
  let productValue: string | null;

  let fromUrl: string | null;
  let redirectUrl = "https://www.gianlucamech-tisanoreica.com/";
  const searchParams = new URLSearchParams(window.location.search);

  if (!(productType = searchParams.get("type") as ProductIdentifier | null)) {
    window.location.replace(redirectUrl);
  }

  if (productType !== "sku" && productType !== "sku_model") {
    // if type is now allowed redirect to product 404 page
    window.location.replace("/product404.php");
  }

  if (!(productValue = searchParams.get("value") as string | null)) {
    window.location.replace(redirectUrl);
  }

  const linkEl = document.createElement("a");
  if ((fromUrl = searchParams.get("from") as string | null)) {
    // mounts a link to go back to product page
    linkEl.classList.add("rt-link");
    linkEl.href = fromUrl;
    linkEl.textContent = translations.k_header_back_to_product;
  } else {
    // mounts a link that redirects the user to customer homepage
    linkEl.classList.add("rt-link");
    linkEl.href = redirectUrl;
    linkEl.textContent = "Home";
  }
  document.getElementById("rt_header")?.appendChild(linkEl);

  return [productType, productValue] as [ProductIdentifier, string];
};

export const showStorageBar = (shouldShow: boolean) => {
  const storageBarEl = document.getElementById("rt_storage_bar")!;
  if (shouldShow) {
    storageBarEl.classList.add("rt-storage-bar--visible");
  } else {
    storageBarEl.classList.remove("rt-storage-bar--visible");
  }
};
